<template>
  <ion-icon
    class="icon-age"
    :src="props.src"
    @mousedown="startPress"
    @mouseup="endPress"
    @mouseleave="endPress"
    @touchstart.passive="startPress"
    @touchend="endPress"
    @touchcancel="endPress"
    @touchmove.passive="endPress"
  />
</template>

<script setup lang="ts">
import { IonIcon } from '@ionic/vue';
import useFooterHeadLogic from './logic';

const props = defineProps({
  src: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['markChange']);

const {
  endPress,
  startPress,
} = useFooterHeadLogic(emit);
</script>

<style scoped lang="less">
@text-color2: var(--ep-color-icon-highlight);

.icon-age {
  font-size: 2.25rem;
  color: @text-color2;
}
</style>
