<template>
  <div class="arrow-container">
    <ion-icon :class="[isInvalid('left'), 'left']" :icon="loadImage.arrow.left" @click="emits('swiperToSlide', 'prev')" />
    <ion-icon :class="[isInvalid('right'), 'right']" :icon="loadImage.arrow.right" @click="emits('swiperToSlide')" />
  </div>
</template>

<script setup lang="ts">
  import { IonIcon } from '@ionic/vue';

  const emits = defineEmits(['swiperToSlide']);

  const props = defineProps({
    loadImage: { type: Object, default: () => {} }, // 加载图片
  });
</script>
