<script setup lang="ts">
import { IonIcon } from '@ionic/vue'
import useNavigaterBack from '@/hooks/useNavigaterBack'
import { getTheme } from '@/theme/hooks'
const { theme } = getTheme()
interface TabItem {
  name: string,
  id: string | number
  tagValue?: string | number
  tags?: string
}
const tabValue = defineModel('tabValue')
const router = useRouter()

const emit = defineEmits<{
  (e: 'update:tabValue', i: number): void,
  (e: 'tabChange', i: number): void,
  (e: 'recordHandle'): void,
}>()
withDefaults(defineProps<{
  contentText: string
  isShowCloseicon?: boolean
  tabList?: TabItem[]
}
>(), {
  isShowCloseicon: false
});
const gotoClient = () => {
  ///notification
  router.push('/notification')
}
const handleClick = (e: CustomEvent) => {


  let i = e.detail.value
  emit("update:tabValue", i)
  emit("tabChange", i)
}
const closePage = () => {
  useNavigaterBack()
}
const recordHandle = () => {
  emit("recordHandle")
}


</script>
<template>

  <div :class="['header-wrap', theme]">
    <div class="top flex-start"> <span>{{ contentText }}</span>
      <div class="icon-area flex-center"><ion-icon slot="icon-only" class="record" @click="recordHandle"
          src="/first/svg/assets/record.svg" /></div>
    </div> <ion-icon slot="icon-only" class="close" src="/first/svg/login/close.svg" v-if="isShowCloseicon"
      @click="closePage" />
    <p class="text">{{ $t('viewsAssets.contactUs') }} <span class="client" @click="gotoClient">
        <ion-icon slot="icon-only" src="/first/svg/perfil/customer.svg" />
        {{ $t('viewsAssets.suporteOnline') }}</span> {{ $t('viewsAssets.Obrigado') }}!
    </p>


  </div>

</template>

<style scoped lang="less">
.header-wrap {
  width: 24.375rem;
  .bg-200();
  padding: .625rem .75rem 0;
  box-sizing: border-box;
  position: relative;


  .top {
    line-height: 1.875rem;

    span {
      .dynamic-font(@fontSize: --font-size-20, @fontWeight: --font-weight-medium)
    }

    .icon-area {
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      .bg-link();
      margin-left: .625rem;

      ion-icon.record {
        .font-size(--font-size-16);
        .color-text-100();

      }
    }

  }

  ion-icon.close {
    .font-size(--font-size-18);
    .color-text-100();
    position: absolute;
    top: 1rem;
    right: 1rem;

  }

  .text {
    width: 18.75rem;
    .dynamic-font(@color: --color-text-40);
    padding: 1.25rem 0;
    line-height: 1.125rem;

    span {
      .dynamic-font(@color: --color-primary-800, @fontWeight: --font-weight-bold);
      white-space: nowrap;
      cursor: pointer;

      ion-icon {
        .font-size(--font-size-18);
        vertical-align: top;
      }
    }
  }

  .tab-list {
    padding: 3px;
    box-sizing: border-box;
    width: 22.5rem;
    height: 2.25rem;
    overflow-x: auto;
    .rounded-middle();
    .bg-300();

    li {
      flex: 1;
      text-align: center;
      height: 1.875rem;
      // max-width: 10.875rem;
      .dynamic-font(@color: --color-text-40, @fontSize: --font-size-14);
      line-height: 1.875rem;
      .rounded-small();
    }

    li.active {
      .dynamic-font(@color: --color-text-100, @fontWeight: --font-weight-bold, @fontSize: --font-size-14);
      .bg-100()
    }

  }

  .heard-bottom {
    padding-bottom: 12px;

    ion-segment {
      --background: var(--color-bg-300);
      width: 22.5rem;
      height: 3rem;
      border-radius: var(--rounded-button-tab) !important;

    }

    ion-segment-button.ios::part(native) {
      color: var(--color-text-40);
    }

    .segment-button-checked.ios::part(native) {
      color: var(--color-text-100);
      font-weight: var(--font-weight-bold);
    }

    ion-segment-button::part(indicator-background) {
      background: var(--color-bg-100)
    }

    ion-segment-button {
      --border-radius: var(--rounded-button-tab);
      height: 2.75rem;
      --padding-start: 12px;
      --padding-end: 12px;
      min-width: 7.5rem;
    }

    .tag {
      position: absolute;
      min-width: 2.375rem;
      height: .8125rem;
      right: -0.625rem;
      top: 0;
      line-height: .875rem;
      .bg-primary-800();
      z-index: 100;
      padding-left: .75rem;
      border-radius: 0 var(--rounded-button-tab) 0 var(--rounded-button-tab);
      .dynamic-font(@color: --color-text-tag, @fontSize: --font-size-10, @fontWeight: --font-weight-bold);

    }

  }



}

.header-wrap.purple-light {
  background-color: var(--color-bg-500);

  .top {

    .icon-area {
      background-color: var(--color-text-100);
    }

  }

  .text {
    .dynamic-font(@color: --color-text-80);
  }
}

.header-wrap.amber-purple {
  background-color: var(--color-bg-100);

  .top {
    line-height: 1.875rem;

    span {
      color: var(--text-color-light-purple-1-100);
    }

    .icon-area {
      background: var(--theme-color-800);
    }

  }

  .text {
    color: var(--text-color-light-purple-1-100);
  }

  .client {
    color: var(--color-currency)
  }

}

#assets-header-component {
  .style() {
    .header-wrap {
      width: 24.375rem;
      background: var(--ep-color-background-fill-surface-raised-L1);
      padding: .625rem .75rem 0;
      box-sizing: border-box;
      position: relative;

      .top {
        line-height: 1.875rem;

        span {
          font-size: 20px;
          font-weight: 500;
          color: var(--ep-color-text-default);
        }

        .icon-area {
          border-radius: 50%;
          width: 1.5rem;
          height: 1.5rem;
          background: var(--ep-color-text-highlight);
          margin-left: .625rem;

          ion-icon.record {
            font-size: 1rem;
            color: var(--ep-color-icon-inverse);

          }
        }

      }

      ion-icon.close {
        font-size: 1.125rem;
        color: var(--ep-color-icon-default);
        position: absolute;
        top: 1rem;
        right: 1rem;
      }

      .text {
        width: 18.75rem;
        font-size: .75rem;
        font-weight: 400;
        color: var(--ep-color-text-default);
        padding: 1.25rem 0;
        line-height: 1.125rem;

        span {
          font-size: .75rem;
          font-weight: 700;
          color: var(--ep-color-text-highlight);
          white-space: nowrap;
          cursor: pointer;

          ion-icon {
            font-size: 1.125rem;
            vertical-align: top;
          }
        }
      }

    }

  }
}

.new-skin-symbol {
  #assets-header-component.style();
}
</style>
