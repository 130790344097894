<template>
  <span 
    class="dot"
  >{{messageQuantity}}
  </span>
</template>

<script setup lang="ts">
import useMessageLogic from '@/views/tabbar/tabs/inicio/components/MarqueeView/components/message/logic';

const { messageQuantity } = useMessageLogic();
</script>

<style lang="less" scoped>
@import './index.less';
</style>
