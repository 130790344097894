<template>
  <div class="content-head">
    <ion-label class="title">{{ merchantName }}</ion-label>
    <ion-label class="subtitle">©2025 {{ merchantName }} {{ $t('subtitle.rightsReserved') }}.</ion-label>
    <Age18 />
  </div>
</template>

<script setup lang="ts">
import { IonLabel } from '@ionic/vue';
import Age18 from '@/views/tabbar/tabs/inicio/components/FooterContent/components/age18/index.vue';
import useFooterHeadLogic from '../logic';

const { 
  merchantName,
} = useFooterHeadLogic(emit);
</script>

<style scoped lang="less">
@import './index.less';
</style>
