<template>
  <div class="content">
    <ion-label class="content-item" v-for="(item,index) in dateList" :key="index">{{ item }}</ion-label>

    <div class="version-box">
      <slot name="version"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IonLabel } from '@ionic/vue';
import useFooterCenterLogic from '../logic';


const { dateList } = useFooterCenterLogic();
</script>

<style scoped lang="less">
@import './index.less';
</style>
