<template>
  <div class="megaphone-wrapper">
    <ion-icon class="megaphone" :src="loadImage.megaphone" />
    <Marquee class="label" :content="marqueeContent" />
    <component v-for="item in componentList" :is="item.component" :key="item.componentId" v-bind="item.options"/>
  </div>
</template>

<script setup lang="ts">
import { IonIcon } from '@ionic/vue';
import Marquee from '@/components/Marquee.vue';
import useMarqueeLogic from '@/views/tabbar/tabs/inicio/components/MarqueeView/logic';

const props = defineProps({
  loadImage: {
    type: Object,
    default: () => ({})
  },
  componentList: {
    type: Array,
    default: () => []
  }
})

const { marqueeContent } = useMarqueeLogic();
</script>

<style lang="less" scoped>
@import './index.less';
</style>
