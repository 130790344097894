
<script setup lang="ts">
import AmountBtn from './AmountBtn.vue'
import AmountBtnDefault from './AmountBtnDefault.vue'
import { getTheme } from '@/theme/hooks'
const { theme, skin } = getTheme()
export interface AmountBtnItem {
  amount: number
  rate?: number
}
const emit = defineEmits<{
  (e: 'amountHandle', amount: number): void
}>()
let props = defineProps<{
  amountBtnList: AmountBtnItem[],
  amountInput: number | string
}
>()

const  currentComp =computed(() => {
  return skin === 'default' ? AmountBtnDefault : AmountBtn
})

const  classNames =computed(() => {
  return `${skin === 'default' ? 'default' : 'first'}-btn-list-wrap`
})

const amountHandle = (item: AmountBtnItem) => {
  if (item.amount === props.amountInput) return
  emit('amountHandle', item.amount)
}

</script>
<template>
  <div class="flex-between":class="[theme, classNames]">
    <template v-for="item in amountBtnList">
      <currentComp v-bind="{ ...item, isActive: item.amount == amountInput }" @click="() => amountHandle(item)" />
    </template>

    <div class="w-[7.125rem]"></div>

  </div>

</template>

<style scoped lang="less">
.first-btn-list-wrap {
  width: 24.375rem;
  padding: .625rem .625rem .125rem .625rem;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-content: space-between;

}

.default-btn-list-wrap {
  width: 22.5rem;
  flex-wrap: wrap;
  align-content: space-between;
 
 
}
</style>
