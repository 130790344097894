<template>
  <div class="btn-container">
    <div class="ios-btn" @click="appleHandle">
      <div class="logo-container">
        <ion-icon class="logo" :src="loadImage.ios" />
        <ion-label class="text">{{ $t('viewsTabbar.appInstall4') }}</ion-label>
      </div>
    </div>
    <div class="android-btn" @click="androidHandle">
      <div class="logo-container">
        <ion-icon class="logo" :src="loadImage.android" />
        <ion-label class="text">{{ $t('viewsTabbar.appInstall5') }}</ion-label>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IonIcon, IonLabel } from '@ionic/vue';
import useAppInstallLogic from '@/views/tabbar/tabs/inicio/components/AppInstall/logic'


const props = defineProps({
  loadImage: {
    type: Object,
    default: () => ({}),
  },
})
const { appleHandle, androidHandle } = useAppInstallLogic();

</script>

<style lang="less" scoped>
@import './index.less';
</style>
