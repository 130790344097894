import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useGameStore } from '@/store/game'


export default (props: any) => {
  const { t } = useI18n()
  const router = useRouter()
  const gameStore = useGameStore()

  const hotTab = computed(() => ({
    id: 'null',
    code: "ONE_API_HOT",
    gameType: 'POPULAR',
    name: t('sort.POPULAR'),
    src: props.loadImage.icon('POPULAR')
  }))

  const list = computed(() => {
    return [hotTab.value].concat(gameStore.homePlatformList.map(item => {
      const { gameType } = item;
      const { loadImage } = props;
      return {
        ...item,
        src: loadImage.icon(gameType)
      }
    }))
  })

  const sortHomeGamesList = computed(() => {
    if (gameStore.homeGames.length) {
      return gameStore.homeGames.sort(<T extends { gameTypeSort: number | null }>(a: T, b: T) => {
        const gameTypeSortA = a.gameTypeSort ?? 0;
        const gameTypeSortB = b.gameTypeSort ?? 0;
        return gameTypeSortB - gameTypeSortA;
      });
    }
    return [];
  });

  const jumpPlatformCategoryPage = (platformId: string) => {
    if (platformId == 'null') {
      router.push('/game/search');
      return;
    }
    for (let i = 0; i < sortHomeGamesList.value.length; i++) {
      const { gameType, platformList } = sortHomeGamesList.value[i] as { gameType: "SPORTS" | "ELECTRONIC" | "CHESS" | "FISHING" | "VIDEO" | "LOTTERY", platformList: any[] };
      for (let j = 0; j < platformList.length; j++) {
        const { id } = platformList[j];
        if (id === platformId) {
          if(gameType === 'SPORTS') {
            router.push('/game/category/sport');
            return;
          }
          router.push(`/game/category/${gameType}/${platformId}`);
          return;
        }
      }
    }
  }

  return {
    list,
    jumpPlatformCategoryPage
  }
}