<script setup lang="ts">
import { formatMoneyToShow } from '@/utils/custom'
const emit = defineEmits<{
  (e: 'amountHandle', amount: number): void
}>()
defineProps<{
  amountList: number[]
  amount: string,
  balance: number
}
>()

const amountHandle = (amount: number) => {
  emit('amountHandle', amount)
}
</script>
<template>
    <ul class="amount-list flex-between">
      <li v-for="it in amountList" :key="it"
        :class="['amount-item', { 'active': Number(amount) === it, 'disabled': balance < it }]"
        @click="amountHandle(it)">
        <span class="amount">{{ formatMoneyToShow(it) }}</span>
      </li>
      <li></li>
      <li></li>
    </ul>
</template>

<style scoped lang="less">
#withdraw-main-amountBtnList-index {
  .defaultStyle(@bg: --color-bg-50, @color: --color-text-gray-200, @activeColor: --color-text-white-100, @activeBg: --theme-color-gradient-300) {

    .amount-list {
      flex-wrap: wrap;
      font-size: var(--font-size-16);

      li {
        width: 5rem;

      }

      .amount-item {
        height: 2.5rem;
        line-height: 2.5rem;
        cursor: pointer;
        text-align: center;
        margin-bottom: 0.625rem;
        background: var(@bg);
        color: var(@color);
        border-radius: var(--rounded-middle);
        font-weight: var(--font-weight-regular);

      }

      .disabled {
        pointer-events: none;
      }

      .active {
        background: var(@activeBg);
        color: var(@activeColor);
        font-weight: var(--font-weight-bold);
      }
    }
  }

  .borderStyle(@color: --theme-color-600) {

    .amount-list .active {
      border: 1px solid var(@color);
    }
  }

  .style(@bg: --color-bg-300, @color: --color-text-100) {
    .amount-list {
      flex-wrap: wrap;
      margin-top: .625rem;
      font-size: var(--font-size-12);

      li {
        width: 7.125rem;
      }

      .amount-item {
        height: 2.375rem;
        text-align: center;
        background: var(--color-bg-300);
        border-radius: var(--rounded-middle);
        line-height: 2.625rem;
        margin-bottom: .75rem;
        cursor: pointer;
        color: var(@color);
        font-weight: var(--font-weight-medium);

      }

      .active {
        border: 1px solid var(--color-primary-900);
        background: var(--color-btn-bg-active-w);

      }

    }

  }

  .amberPurpleStyle(@beforeBg: #2c294d) {
    .amount-list {
      font-weight: 600;
      position: relative;

      .active {
        border: none;
        background: linear-gradient(160deg, #EABD5B 15%, #7041F3 50%, #7041F3 80%, #F5C84C 100%);
        position: relative;

        &::before {
          content: "";
          position: absolute;
          top: 1px;
          left: 1px;
          right: 1px;
          bottom: 1px;
          background: @beforeBg;
          z-index: 1;
          border-radius: inherit;
        }
      }

      .amount {
        position: relative;
        z-index: 2;
        color: var(--text-color-light-purple-1-100)
      }
    }


  }

}





.green-dark,
.yellow-dark {
  #withdraw-main-amountBtnList-index.style()
}

.purple-light {
  #withdraw-main-amountBtnList-index.style(@bg: --color-bg-200)
}

.amber-purple {
  .second {
    #withdraw-main-amountBtnList-index.style(@bg: --color-bg-200, @color: --text-color-light-purple-1-100);
    #withdraw-main-amountBtnList-index.amberPurpleStyle()
  }

  .default {
    #withdraw-main-amountBtnList-index.defaultStyle(@color: --text-color-light-purple-1-100, @bg: --color-bg-200, );
    #withdraw-main-amountBtnList-index.amberPurpleStyle(#544d80)
  }
}




.blue-default {
  #withdraw-main-amountBtnList-index.defaultStyle();
  #withdraw-main-amountBtnList-index.borderStyle();
}

/* green-default 皮肤的样式 */
.green-default, .forest-green{
  #withdraw-main-amountBtnList-index.defaultStyle(@bg: --color-bg-100, @activeBg: --theme-color-gradient-100);

}
.auroral-yellow{
  #withdraw-main-amountBtnList-index.defaultStyle(@bg: --color-bg-100, @activeBg: --color-bg-100);
  #withdraw-main-amountBtnList-index.borderStyle();

}



#withdraw-main-amountBtnList-new-index {
  .style() {
    .amount-list {
      flex-wrap: wrap;
      margin-top: .625rem;
      font-size: .75rem;

      li {
        width: 7.125rem;
      }
      .amount-item {
        height: 2.375rem;
        text-align: center;
        background: var(--ep-color-background-fill-surface-raised-L2);
        border-radius: .375rem;
        line-height: 2.625rem;
        margin-bottom: .75rem;
        cursor: pointer;
        color: var(--ep-color-text-default);
        font-weight: 500;
      }
      .active {
        border: 1px solid var(--ep-color-border-selected);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 16.59%, var(--ep-color-background-fill-glow-primary-opacity-40, rgba(58, 140, 227, 0.40)) 110.34%), var(--ep-color-background-fill-surface-raised-L2, #1A1F30);;

      }

    }

  }
}
.new-skin-symbol{
  #withdraw-main-amountBtnList-new-index.style();
}


</style>
