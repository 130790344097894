<template>
  <div class="content-head">
    <ion-img :src="appLogo" class="logo"/>
    <LanguageSelectionBox class="language"/>
  </div>
</template>

<script setup lang="ts">
import { IonImg } from '@ionic/vue';
import LanguageSelectionBox from '@/views/tabbar/components/DrawerLeft/components/language/1/index.vue'
import useFooterHeadLogic from '../logic';

const props = defineProps({

});

const emit = defineEmits(['markChange']);

const {
  appLogo,
} = useFooterHeadLogic(emit);
</script>

<style scoped lang="less">
@import './index.less';
</style>