<template>
  <ion-tab-bar class="tab-bar" ref="tabBarRef">
    <div class="inner-wrap" :style="wrapStyle">
      <template v-if="componentList.length > 0">
        <component v-for="(item, i) in tabList" :item="item" :index="i" :navigator="navigator" :key="componentList[0].componentId" :is="componentList[0].component" v-bind="componentList[0].options" />
      </template>
    </div>
  </ion-tab-bar>
</template>

<script setup lang="ts">
import { IonTabBar, IonLabel, IonIcon } from "@ionic/vue";
import useTabLogic from "@/views/tabbar/components/tabBar/logic";


const props = defineProps({
  tabTextMaps: {
    type: Array,
    default: () => []
  },
  componentList: {
    type: Array,
    default: () => []
  },
  wrapStyle: {
    type: Object,
    default: () => ({})
  }
})

const { tabList, navigator, tabBarRef } = useTabLogic(props);

</script>

<style lang="less" scoped>
@import "./index.less";
</style>
