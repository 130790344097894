<script setup lang="ts">

import { getTheme } from '@/theme/hooks'
import {
  IonSegment,
  IonSegmentButton
} from '@ionic/vue'
import { AssetsTabInfo } from '@/views/withdraw/type'
const { theme } = getTheme()
const emit = defineEmits<{
  (e: 'tabChange', tabId: number): void
  (e: 'update:tabId', tabId: number): void
}>()
const props = defineProps<{
  tabList: AssetsTabInfo[]


  loading: boolean,
  activeBgColor?: string
}
>()
const tabId = defineModel<number>('tabId', { required: true })

const tabChange = (e: CustomEvent) => {
  const activeId = e.detail.value
  if (activeId === tabId.value) return
  emit('tabChange', activeId)
  emit('update:tabId', activeId)

}

</script>
<template>

  <div :class="['pay-list', theme]">
    <ion-segment :value="tabId" :scrollable="true" mode='ios' @ionChange="tabChange" :disabled="loading">

      <ion-segment-button v-for="(it) in tabList" :value="it.id">
        {{ it.name }}
        <div class="tag" v-if="it.tagValue"> {{ it.tagValue }}</div>
      </ion-segment-button>

    </ion-segment>
  </div>
</template>

<style scoped lang="less">
.pay-list {
  width: 24.375rem;
  padding: .75rem;
  .rounded-large-b();
  .bg-200();
  box-shadow: 0px 4px 28.4px 0px rgba(0, 0, 0, 0.10);

  ion-segment {
    --background: var(--color-bg-300);
    width: 22.5rem;
    height: 3rem;
    border-radius: var(--rounded-button-tab) !important;

  }

  ion-segment-button.ios::part(native) {
    color: var(--color-text-40);
  }

  .segment-button-checked.ios::part(native) {
    color: var(--text-color-white-100);
    font-weight: var(--font-weight-bold);

  }

  ion-segment-button::part(indicator-background) {
    background: var(--color-bg-100)
  }

  ion-segment-button {
    --border-radius: var(--rounded-button-tab);
    height: 2.75rem;
    --padding-start: 12px;
    --padding-end: 12px;
    min-width: 7.5rem;

  }

  .tag {
    position: absolute;
    min-width: 2.375rem;
    height: .8125rem;
    right: -0.625rem;
    top: 0;
    line-height: .875rem;
    background-color: var(--theme-color-800);
    z-index: 100;
    padding: 0 .375rem;
    text-align: center;
    border-radius: 0 var(--rounded-button-tab) 0 var(--rounded-button-tab);
    .dynamic-font(@color: --color-text-tag, @fontSize: --font-size-10, @fontWeight: --font-weight-bold);

  }

}

.pay-list.purple-light {

  background-color: var(--color-bg-500);


  ion-segment {
    --background: var(--color-bg-300);
  }

  ion-segment-button.ios::part(native) {

    color: var(--color-text-80);

  }

  .segment-button-checked.ios::part(native) {
    color: var(--color-text-secondary-1);
    font-weight: var(--font-weight-bold);
  }

  ion-segment-button::part(indicator-background) {
    background: var(--theme-color-800)
  }


  .tag {
    background-color: var(--accent-color-red);
  }

}


.pay-list.amber-purple {
  background-color: var(--color-bg-100);

  ion-segment-button.ios::part(native) {
    color: var(--text-color-light-purple-2-100);
  }

  .segment-button-checked.ios::part(native) {
    color: var(--text-color-light-purple-1-100);

  }


  .tag {
    background: var(--gradients-orange-purple);
  }

}

#recharge-main-comp-tab {
  .style() {
    .pay-list {
      width: 24.375rem;
      padding: .75rem;
      border-radius: 0px 0px 10px 10px;
      background: var(--ep-color-background-fill-surface-raised-L1);
      box-shadow: 0px 4px 28.4px 0px rgba(0, 0, 0, 0.10);

      ion-segment {
        --background: var(--ep-color-background-fill-body-default);
        width: 22.5rem;
        height: 3rem;
        border-radius: .375rem !important;

      }

      ion-segment-button.ios::part(native) {
        color: var(--ep-color-text-weaker);
      }

      .segment-button-checked.ios::part(native) {
        color: var(--ep-color-text-default);
        font-weight: 700;

      }

      ion-segment-button::part(indicator-background) {
        background: var(--ep-color-background-fill-surface-raised-L2)
      }

      ion-segment-button {
        --border-radius: .25rem;
        height: 2.75rem;
        --padding-start: 12px;
        --padding-end: 12px;
        min-width: 7.5rem;

      }

      .tag {
        position: absolute;
        min-width: 2.375rem;
        height: .8125rem;
        right: -0.625rem;
        top: 0;
        line-height: .875rem;
        background: var(--ep-color-background-fill-tips);
        z-index: 100;
        padding: 0 .375rem;
        text-align: center;
        border-radius: 0 4px 0 4px;
        font-size: .625rem;
        font-weight: 700;
        color: var(--ep-color-text-inverse);
      }

    }

  }
}

.new-skin-symbol {
  #recharge-main-comp-tab.style();
}
</style>
