<template>
  <ion-segment :class="className" mode="ios" scrollable v-model="tabValue" @ionChange="tabChange" @mousedown="handleMouseDown"
    @mouseup="handleMouseUp" @mouseleave="handleMouseLeave" @mousemove="handleMouseMove">
    <slot :tabs="tabs" :tabValue="tabValue" :disableTab="disableTab" />
  </ion-segment>
</template>


<script setup lang="ts">
  import { IonSegment } from '@ionic/vue';
  import useSegmentLogic from './rowPlatformLogic'

  const emit = defineEmits(['tabChange']); // 定义默认传递事件方法(update:modelValue为v-model传值)


  const props = defineProps({
    sticky: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 1
    },
    height: {
      type: Number,
      default: 0
    }
  })// 定义默认绑定参数

  const className = computed(() => {
    return `layout${props.type}`
  })

const {
  tabs,
  tabValue,
  disableTab,
  tabChange,
  handleMouseDown,
  handleMouseUp,
  handleMouseLeave,
  handleMouseMove,
} = useSegmentLogic(props, emit)

</script>

<style lang="less" scoped>
  ion-segment {
    --background: transparent;
    gap: 0.625rem;
    border-radius: 0.875rem;
  }
</style>