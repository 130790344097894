import { useElementStore } from '@/store/element';



export default (props: any) => {
  const elementStore = useElementStore();
  const { tabBarHeight } = toRefs(elementStore); // 底部导航栏高度

  const className = computed(() => {
    return `layout${props.type}`
  })

  const needIconWall = computed(() => {
    return [1, 2].includes(props.type)
  })

  const elStyle = computed(() => {
    return {
      bottom: `calc(${tabBarHeight.value}px + env(safe-area-inset-bottom) + 1rem)`
    }
  })

  const scrollToTop = () => {
    // ion-content滚动到指定位置
    const mainContent = document.querySelector('.main-content') as any;
    if (mainContent) {
      mainContent.scrollEl.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  return {
    elStyle,
    className,
    needIconWall,
    scrollToTop,
  }
}