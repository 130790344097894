<template>
  <ion-toolbar class="header">
    <ion-buttons class="menu-btn" slot="start" @click="menuHandle">
      <ion-icon class="menu-icon" :src="drawerLeftIsOpen ? loadImage.menu.open : loadImage.menu.close" />
    </ion-buttons>

    <ProgressiveImages class="logo" :src="appLogo" slot="start" />

    <ion-buttons slot="end">
      <component v-for="item in componentList" :key="item.componentId" :is="item.component" v-bind="item.options" />
    </ion-buttons>
  </ion-toolbar>
</template>

<script setup lang="ts">
import { IonToolbar, IonButtons, IonIcon } from '@ionic/vue';
import ProgressiveImages from '@/components/GameCard/progressiveImages.vue';

import useHeaderBarLogic from '@/views/tabbar/tabs/inicio/components/HeaderBar/logic';

const props = defineProps({
  componentList: {
    type: Array,
    default: () => []
  },
  loadImage: {
    type: Object,
    default: () => ({}),
  }
})

const {
  appLogo,
  menuHandle,
  drawerLeftIsOpen,
} = useHeaderBarLogic();

</script>

<style lang="less" scoped>
@import './index.less';
</style>
