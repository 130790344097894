<template>
  <section class="game-platform">
    <ion-menu-toggle>
      <div
        v-for="item in list"
        class="platform-item"
        :key="item.gameType"
        @click="jumpPlatformCategoryPage(item.id)"
      >
        <ion-icon class="platform-item-icon" :src="item.src" />
        <ion-label class="platform-item-label">{{ item.name }}</ion-label>
      </div>
    </ion-menu-toggle>
  </section>
</template>


<script setup lang="ts">
import { IonMenuToggle, IonIcon, IonLabel } from '@ionic/vue';
import useLogic from '../logic';


const props = defineProps({
  loadImage: {
    type: Object,
  }
});

const {
  list,
  jumpPlatformCategoryPage
} = useLogic(props)

</script>

<style scoped lang="less">
@import './index.less';
</style>
