<template>
  <div class="footer-container">
    <template v-for="item in componentList" :key="item.componentId">
      <component v-if="item.componentName === 'tabbar_inicio_FooterContent_footerCenter'" :is="item.component" v-bind="item.options" @markChange="markChange">
        <template #version>
          <div v-if="showMark" class="version">
            <ion-label>{{ os + browser + sd }}</ion-label>
            <ion-label>{{ getBuildVersion() }}</ion-label>
            <ion-label>{{ origin }}</ion-label>
            <ion-label>{{ webPushRegId }}</ion-label>
          </div>
        </template>
      </component>
      <component v-else :is="item.component" v-bind="item.options" @markChange="markChange"/>
    </template>
  </div>
</template>

<script setup lang="ts">
import { IonLabel } from '@ionic/vue';
import { useSystemStore } from "@/store/system";
import { useTenantStore } from "@/store/tenant";
import { getBuildVersion } from '@/utils/app';

const props = defineProps({
  componentList: {
    type: Array,
    default: () => []
  }
})



const systemStore = useSystemStore(); // 系统信息
const tenantStore = useTenantStore();	// 租户信息
const appStore = useAppStore(); // app信息
const origin = location.origin;
const webPushRegId = computed(() => appStore.webPushRegId);
const os = computed(() => systemStore.os); // 操作系统信息
const sd = computed(() => systemStore.app?.build); // app类型
const browser = computed(() => systemStore.browser); // 浏览器信息

const showMark = ref(false);

const markChange = (value: boolean) => {
  showMark.value = value;
}

</script>

<style lang="less" scoped>
@import './index.less';
</style>


