<!-- 通用搜索组件 -->
<template>
    <ion-toolbar class="toolbar-search">
        <ion-searchbar mode="md" id="searchbar" class="relative" v-model="searchValue" show-clear-button="never"
            :placeholder="placeholder" @keyup.enter="handleSearch">
            <ion-spinner name="bubbles" v-if="loading" />
            <ion-icon :icon="searchOutline" @click="handleSearch" v-else />
        </ion-searchbar>
    </ion-toolbar>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { searchOutline } from 'ionicons/icons';
import { IonToolbar, IonSearchbar, IonSpinner, IonIcon } from '@ionic/vue';

const props = defineProps<{
    loading?: boolean;
    placeholder?: string;
    modelValue?: string;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void;
    (e: 'search'): void;
}>();

const searchValue = ref(props.modelValue || '');

watch(() => props.modelValue, (newVal) => {
    searchValue.value = newVal || '';
});

watch(searchValue, (newVal) => {
    emit('update:modelValue', newVal);
});
/**
 * search事件回调
 */
const handleSearch = () => {
    console.log('searchValue',searchValue.value);
    console.log('props.modelValue',props.modelValue);
    emit('search');
};
</script>

<style lang="less">
@import './index.less';
</style>