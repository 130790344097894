<script setup lang="ts">
import Card from '@/views/withdraw/comp/Card.vue'
import { IonModal, IonContent, IonIcon, IonRadio, IonRadioGroup } from '@ionic/vue';
import { close } from 'ionicons/icons'
import { CardInfo, RegionCodeType } from '@/views/withdraw/type'

const tenantStore = useTenantStore() // 商户信息
const router = useRouter()

const emit = defineEmits<{
  (e: 'changeActiveAccount', relatedCode: string): void
}>()
let props = withDefaults(defineProps<{
  accountList: CardInfo[]
  bindAccountMax: number
}
>(), {
  accountList: () => []
});
const activeAccount = ref<CardInfo>(props.accountList[0])
const { tenantInfo } = toRefs(tenantStore)
const gotoAccount = () => {
  router.push("/withdrawalAccount")
}

const isShowAddCard = computed(() => props.bindAccountMax > props.accountList.length)
const relatedCode = ref('')
const modalOpen = ref(false)
const handleExpand = () => {
  modalOpen.value = true
}
const didDismiss = () => {
  modalOpen.value = false
}
const ionChange = (e: CustomEvent) => {
  let activeCode = e.detail.value
  emit('changeActiveAccount', activeCode)
  relatedCode.value = activeCode
  activeAccount.value = props.accountList.find(it => it.relatedCode === activeCode)

}
watch(() => props.accountList, (list) => {
  if (!list.length) return
  if (!list[0].relatedCode) return
  relatedCode.value = list[0].relatedCode
  activeAccount.value = list[0]
}, { immediate: true })


const getAccountValue = () => {

  type ValueType = keyof Pick<CardInfo, 'BANKACCOUNT' | 'IFSC'>
  const regionCodeMap: Record<RegionCodeType, ValueType> = {
    "IN": 'IFSC',
    "PH": 'BANKACCOUNT',
  }
  const currentRegionCode = tenantInfo.value?.code as RegionCodeType
  const key = regionCodeMap[currentRegionCode]
  return {
    account: activeAccount.value[key],
    realName: activeAccount.value.REALNAME,
    bankAccount: activeAccount.value.BANKACCOUNT,
    tenantWithdrawTypeSubId: activeAccount.value.tenantWithdrawTypeSubId

  }

}

defineExpose({
  getAccountValue
})
</script>
<template>
  <div class="bind-title flex-between">
    <span>{{ $t('viewsAssets.withdrawalAccount') }}</span><span class="my-account" @click="gotoAccount">{{
      $t('viewsAssets.myAccount') }}</span>
  </div>
  <Card type="append" v-if="!accountList.length" appendClassName='append-white' />
  <Card v-else :cardInfo="activeAccount" @handleExpand="handleExpand">
    <template v-if="bindAccountMax === 1">{{ null }}</template>
  </Card>


  <ion-modal class="card-modal" :is-open="modalOpen" @didDismiss="didDismiss" :initial-breakpoint="1"
    :breakpoints="[0, 1]">
    <!-- <ion-header>

    </ion-header> -->
    <ion-content class="card-list">
      <div class="flex-between header-area">
        <p class="title">{{ $t('viewsAssets.withdrawalAccount') }}</p>
        <ion-icon class="close" :icon="close" @click="didDismiss" />
      </div>

      <ion-radio-group :value="relatedCode" @ionChange="ionChange">
        <template v-for="it in accountList">
          <Card :cardInfo="it" class="m-b">

            <ion-radio class='select' :value="it.relatedCode" mode="ios"></ion-radio>
          </Card>
        </template>
      </ion-radio-group>
      <Card type="empty" @addCallback="didDismiss" v-if="isShowAddCard" />

    </ion-content>

  </ion-modal>
</template>

<style scoped lang="less">
.bind-title {
  width: 22.375rem;
  margin: 1.3125rem auto .75rem;
  color: var(--color-text-100);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-12);
  line-height: 1.125rem;
  box-sizing: border-box;
  padding: 0 .75rem;

  .my-account {
    cursor: pointer;
    color: var(--color-link);

  }
}


ion-modal {
  --height: 26.25rem;
}

ion-content.card-list {
  --background: var(--color-bg-300);
  --padding-start: .75rem;
  --padding-end: .75rem;
  --padding-top: 1rem;
  --padding-bottom: 1.125rem;
  height: 26.25rem;


}

ion-content.card-list::part(background) {
  border-radius: .625rem !important;
}

#withdraw-main-comp-bindAccount-index {

  .style(@closeColor: --color-text-100, @radioCheckedBg: --theme-color-800,
    @radioCheckedBorderColor: --line-color, @headerColor: --color-text-100, @radioBorderColor: --color-text-40) {

    .card-list {
      overflow-x: scroll;
      position: relative;

      .header-area {
        color: var(@headerColor);
        line-height: 1.875rem;
        height: 1.875rem;
        margin-bottom: 1.125rem;

        .close {
          color: var(@closeColor);
        }

        .title {
          font-size: 1.25rem;
        }
      }

      .m-b {
        margin-bottom: 1.0625rem;
      }

      ion-radio {
        --color-checked: var(--color-bg-200);
      }

      ion-radio.select.ios::part(container) {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid var(@radioBorderColor);
      }

      .radio-checked.select.ios::part(container) {
        border-color: var(@radioCheckedBorderColor);
        background-color: var(@radioCheckedBg);
      }


    }

  }

}


#withdraw-main-comp-bindAccount-index.style();

.purple-light {
  #withdraw-main-comp-bindAccount-index.style(@radioCheckedBg: --accent-color-orange, @radioCheckedBorderColor: --color-text-secondary-1);
}

.blue-default,
.green-default {
  #withdraw-main-comp-bindAccount-index.style(@radioCheckedBg: --accent-color-yellow);
}

.amber-purple {
  #withdraw-main-comp-bindAccount-index.style(@closeColor: --text-color-white-100, @radioCheckedBg: --accent-color-yellow);
}

.auroral-yellow {
  #withdraw-main-comp-bindAccount-index.style(@closeColor: --color-text-black-100);
}

.new-skin-symbol {
  .bind-title {
    color: var(--ep-color-text-default);
    font-weight: 700;
    font-size: .75rem;
    width: 22.375rem;
    margin: 1.3125rem auto .75rem;
    line-height: 1.125rem;
    box-sizing: border-box;
    padding: 0 .75rem;

    .my-account {
      cursor: pointer;
      color: var(--ep-color-text-info);

    }
  }

  ion-modal {
    --height: 26.25rem;
  }

  ion-content.card-list {
    --background: var(--ep-color-background-fill-surface-raised-L1);
    --padding-start: .75rem;
    --padding-end: .75rem;
    --padding-top: 1rem;
    --padding-bottom: 1.125rem;
    height: 26.25rem;


  }
  #withdraw-main-comp-bindAccount-index.style(@radioCheckedBg: --ep-color-icon-default, @radioCheckedBorderColor: --ep-color-border-default, @headerColor: --ep-color-text-default,
   @radioBorderColor: --ep-color-icon-default,@closeColor: --ep-color-text-default);
}
</style>
