<script setup lang="ts">
import {
  IonCheckbox,
} from '@ionic/vue'
import { getTheme } from '@/theme/hooks'
const { theme } = getTheme()
const emit = defineEmits<{
  (e: 'handleSwitch', event: CustomEvent): void
  (e: 'update:isParticipate', bool: boolean): void
}>()
const isParticipate = defineModel<boolean>('isParticipate', { required: true })
const checked = ref(isParticipate.value)

const ionChange = (e: CustomEvent) => {
  let checked = e.detail.checked
  emit('handleSwitch', e)
  emit('update:isParticipate', checked)
}
</script>
<template>

  <div :class="[theme, 'checkbox-area']">
    <ion-checkbox class="" justify="start" label-placement="end" v-model="checked" @ionChange="ionChange" mode="md">
      {{ $t('viewsTabbar.rechargeBonus') }}
    </ion-checkbox>

  </div>


</template>

<style scoped lang="less">
.checkbox-area {
  padding-left: 13px;
  line-height: 1.125rem;
  .dynamic-font(@color: --color-text-80);

  ion-checkbox {
    --border-color: var(--color-primary-800);
    --border-color-checked: var(--color-primary-800);
    --checkbox-background-checked: var(--color-primary-800);
    --size: 16px;
  }

  ion-checkbox::part(container) {
    .rounded-small();
    border: .0625rem solid var(--color-primary-800);
  }

}

.checkbox-area.amber-purple {
  color: var(--text-color-light-purple-1-100)
}

#activity-switch-new-skin-symbol {
  .style() {
    .checkbox-area {
      padding-left: 13px;
      line-height: 1.125rem;
      font-size: .75rem;
      color: var(--ep-color-text-weaker);

      ion-checkbox {
        --border-color: var(--ep-color-border-brand);
        --border-color-checked: var(--ep-color-border-brand);
        --checkbox-background: var(--ep-color-background-fill-body-default);
        --checkbox-background-checked: var(--ep-color-background-fill-active-primary);
        --size: 1rem;
        --border-radius: .25rem;
      }

      ion-checkbox::part(container) {
        border-radius: .25rem;
        border: 1px solid var(--ep-color-border-brand);
      }

    }

  }


}

.new-skin-symbol {
  #activity-switch-new-skin-symbol.style();
}
</style>
