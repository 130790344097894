// 处理页面可见性的自定义hook
import { onMounted, onUnmounted } from "vue";
import { firstRechargeStatusApi } from "@/api/personal";
import { useUserStore } from "@/store/user";
import { useAppStore } from "@/store/app";
import { useTenantStore } from "@/store/tenant";
import { emitter } from "@/utils/event";

export function usePageVisibility() {

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      if (useTenantStore()?.tenantInfo?.code === "PH") {
        checkFirstRechargeReport();
      }
    }
  };

  onMounted(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
  });

  onUnmounted(() => {
    document.removeEventListener("visibilitychange", handleVisibilityChange);
  });

  // 检查首充回传
  const checkFirstRechargeReport = async () => {
    const reportStatus = useUserStore().getFirstRechargeStatus();
    const isLogin = await useAppStore().checkUserHasLogin();
    if (!reportStatus && isLogin) {
      const res = await firstRechargeStatusApi();
      const { userId, hasSentFirstRechargeAd, firstRechargeAmount, orderNo } = res.hasSentFirstRechargeAd;
      if (!hasSentFirstRechargeAd && firstRechargeAmount) {
        emitter.emit('user/recharge-ad-report', {
          userId,
          tenantId: useTenantStore().tenantInfo?.id,
          amount: Number(firstRechargeAmount) / 100,
          changeTwoType: "",
          currency: useTenantStore().tenantInfo?.currency || "USD",
          isFirstRecharge: true,
          orderNo,
        });
      }
    }
  };
}
