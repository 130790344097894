import { useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';

export default () => {
  const router = useRouter();
  const userStore = useUserStore();

  const unReadCount = computed(() =>  {
    const { unreadMailCount = 0, getUnreadAnnouncementCount = 0 } = userStore;
    const count = unreadMailCount + getUnreadAnnouncementCount;
    return count > 0 ? count : 0;
  })

   // 跳转到客服
   function goToCustomer() {
    router.push('/notification')
  }

  return {
    unReadCount,
    goToCustomer
  }
}