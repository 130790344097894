<template>
  <div class="content">
    <ion-label class="content-item" v-for="(item,index) in dateList" :key="index">{{ item }}</ion-label>
    <PaymentPartner class="payment-partner"/>
    <div class="version-box">
      <Age18 :src="loadImage.age18" @markChange="onMarkChange"/>
      <ion-label class="subtitle">©2025 {{ merchantName }} {{ $t('subtitle.rightsReserved') }}.</ion-label>
      <slot name="version"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IonLabel } from '@ionic/vue';
import PaymentPartner from '@/views/tabbar/tabs/inicio/components/PartView/components/paymentPartner/1/index.vue'
import Age18 from '@/views/tabbar/tabs/inicio/components/FooterContent/components/age18/index.vue';
import useFooterHeadLogic from '@/views/tabbar/tabs/inicio/components/FooterContent/components/footerHead/logic'
import useFooterCenterLogic from '../logic';


const props = defineProps({
  loadImage: {
    type: Object,
    default: () => ({}),
  },
  onMarkChange: {
    type: Function,
    default: () => {},
  },
});

const { merchantName } = useFooterHeadLogic();
const { dateList } = useFooterCenterLogic();
</script>

<style scoped lang="less">
@import './index.less';
</style>
