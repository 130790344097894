// 首页底部导航栏逻辑
import { menuController } from "@ionic/vue";
import { TabbarEnum } from '@/enums/common'
import { useGameStore } from '@/store/game'
import { useTenantStore } from '@/store/tenant'
import router from '@/router'

export default function useTabLogic(props?: { tabTextMaps: string[] }) {
	const route = router.currentRoute
	const activityStore = useActivityStore() // 活动store
	const gameStore = useGameStore() // 游戏store
	const elementStore = useElementStore()
	const tenantStore = useTenantStore();
	const { isShowPromoRedPoint, isShowProfileRedPoint } = toRefs(activityStore)

  const tabBarRef = ref() // 底部导航栏ref
	const taskStore = useTaskStore();
	const promoRedPoint = computed(() => {
		return isShowPromoRedPoint.value || taskStore.completedTheTaskList.length > 0
	})
	// 获取底部导航栏数据
	const initTabTextMaps = ['main.inicio', 'main.promo', 'main.entrar', 'main.withdraw',  'main.perfil'];
	const tabList = computed(() => {
		const tabTextMaps = props?.tabTextMaps || initTabTextMaps;
		const tabMaps = {
			'main.inicio': () => {
				return { tab: TabbarEnum.INITIO, text: 'main.inicio', route: '/main/inicio', point: false }	
			},
			'main.promo': () => {
				return { tab: TabbarEnum.PROMO, text: 'main.promo', route: '/main/promo', point: promoRedPoint.value }
			},
			'main.entrar': () => {
				return { tab: TabbarEnum.ENTRAR, text: 'main.entrar', route: '/main/entrar', point: false }
			},
			'main.withdraw': () => {
				return { tab: TabbarEnum.WITHDRAW, text: 'main.withdraw', route: '/main/withdraw', point: false }
			},
			'main.invite': () => {
				const agencyId = activityStore.activityList?.find(it => it.type === 'Agency')?.id || 0;
				return { tab: TabbarEnum.INVITE, text: 'main.invite', route: `/activity/agency/${agencyId}`, point: false }
			},
			'main.perfil': () => {
				return { tab: TabbarEnum.PERFIL, text: 'main.perfil', route: '/main/perfil', point: isShowProfileRedPoint.value }
			},
			'main.menu': () => {
				return { tab: 'menu', text: 'main.menu', slot: 'menu' }
			},
		}
		return tabTextMaps.map(tab => {
			const fn = tabMaps[tab as keyof typeof tabMaps]
			if (fn) {
				return fn()
			}
		})
	})

  /**
	 * @description 路由跳转
	 * @param route 当前路由
	 */
	async function navigator(path: string) {
		if (path === route.value.path) return
		const pathHandlers = {
			'/main/entrar': async () => {
				return await useHandleRecharge()
			},

			'/main/withdraw': async () => {
				return await useHandleWithdraw()
			},
		}

		const handler = pathHandlers[path as keyof typeof pathHandlers] || null
		if (handler) {
			const result = await handler()

			if (result) return
		}
		router.push(path)
	}

	const showMenu = () => {
		if (gameStore.allGameList.length === 0) {
			gameStore.requestPopularGames()
		}
		menuController.open('main-menu')
	}

	useResizeObserver(tabBarRef, (entries) => {
    const entry = entries[0];
    const { height } = entry.contentRect;
    if (height) {
      elementStore.setTabBarHeight(height)
    }
  })

  return {
    tabList,
		showMenu,
    navigator,
    tabBarRef,
  }
}
