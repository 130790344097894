<template>
  <ion-menu-toggle>
    <slot v-bind="$attrs" :unReadCount="unReadCount" :handleClick="goToCustomer"/>
  </ion-menu-toggle>
</template>

<script setup lang="ts">
import { IonMenuToggle } from '@ionic/vue';
import useLogic from './logic';

const { unReadCount, goToCustomer } = useLogic();
</script>
