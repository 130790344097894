<template>
  <div class="game-wrapper">
    <template v-for="item in componentList" :key="item.componentId">
      <template v-if="item.componentName === 'tabbar_inicio_GameWrapper_Wrapper'">
        <component
          :is="item.component"
          v-for="(gameArray, i) in gameList"
          :style="`order:${i * 2}`"
          :key="String(gameArray.id) + i"
          v-bind="{
            ...item.options,
            ...forTabValueOptions(gameArray),
          }"
          v-intersection-directive="{ observerOptions: {rootMargin: '0px',threshold:0.5}, cb: watchBuoy, index: i, tabKey: gameArray.id }" 
        />
      </template>
      <component v-else :is="item.component" />
    </template>
  </div>
</template>

<script setup lang="ts">
  import vIntersectionDirective from '@/directives/intersection';
  import useLogic from './logic';

const props = defineProps({
  componentList: {
    type: Array,
    default: () => []
  },
  gameList: {
    type: Array,
    default: () => []
  },
  gameWrapperOptions: {
    type: Object,
    default: () => ({})
  },
  watchBuoy: {
    type: Function,
    default: () => {}
  }
})

const {
  forTabValueOptions,
} = useLogic(props)
</script>

<style scoped lang="less">

</style>