
import { copy } from '@/hooks/Copy';
import { useAppStore } from '@/store/app';

export default (emits: any) => {
  const appStore = useAppStore(); // app信息
  const webPushRegId = computed(() => appStore.webPushRegId);

  const pressTimer = ref<NodeJS.Timeout>(); // 按压定时器


  /**
   * @description 开始按压
   */
  function startPress() {
    pressTimer.value = setTimeout(() => {
      emits('markChange', true)
      copy(webPushRegId.value);
    }, 2000);
  }
  /**
   * @description 结束按压
   */
  function endPress() {
    if (pressTimer.value) {
      clearTimeout(pressTimer.value);
      pressTimer.value = undefined;
    }
    emits('markChange', false)
  }


  return {
    endPress,
    startPress,
  }
}
