


export default (props: any) => {
  const forTabValueOptions = (item: any) => {
    const { size, row, hotRow, hotSize, ...rest } = props.gameWrapperOptions;
    let logoType = "1";
    let currentSize = size;
    let currentRow = row;
    let platformId = String(item.id);
    if (item.gameType === "POPULAR") {
      currentRow = hotRow;
      currentSize = hotSize;
    }
    if (item.hot) {
      platformId = String(item.platformId);
    }
    if (['POPULAR', 'SPORTS'].includes(item.gameType) || item.hot) {
      logoType = "2";
    }
    return {
      ...rest,
      logoType,
      platformId,
      size: currentSize,
      row: currentRow,
      platform: item,
      gameList: item.list,
    }
  }

  return {
    forTabValueOptions,
  }
}