// 定义平台类型
type PlatformType = keyof typeof platformImagePosition;


// 图片位置和UI商议好
const platformImagePosition: Record<string, string> = {
  slots_cq9: 'background-position: 0 0',
  ONE_API_CQ9: 'background-position: 0 0',
  ELECTRONIC_tada: 'background-position: -100% 0',
  ONE_API_Tada: 'background-position: -100% 0',
  PP: 'background-position: -200% 0',
  ONE_API_PG: 'background-position: -200% 0',
  ONE_API_PP: 'background-position: -200% 0',
  ELECTRONIC_PP: 'background-position: -200% 0',
  PG: 'background-position: -300% 0',
  KKGAME: 'background-position: -300% 0',
  ELECTRONIC_kk: 'background-position: -300% 0',
  slots_Evoplay: 'background-position: -400% 0',
  ONE_API_Evoplay: 'background-position: -400% 0',
  Betby: 'background-position: -500% 0',
  slots_jdb: 'background-position: -600% 0',
  ONE_API_JDB: 'background-position: -600% 0',
  slots_Spinix: 'background-position: -700% 0',
  ONE_API_Spinix: 'background-position: -700% 0',
  live_evolutoin: 'background-position: 0 -100%',
  ONE_API_Evolution: 'background-position: 0 -100%',
  SPRIBE: 'background-position: -100% -100%',
  ONE_API_Spribe: 'background-position: -100% -100%',
  M8SPORTS: 'background-position: -200% -100%',
  basha: 'background-position: -300% -100%',
  slots_jili: 'background-position: -400% -100%',
  ONE_API_Jili: 'background-position: -400% -100%',
  slots_fc: 'background-position: -500% -100%',
  ONE_API_Fachai: 'background-position: -500% -100%',
  TBGame: 'background-position: -600% -100%',
  CP: 'background-position: -700% -100%',
}

export default {
  /*    components     */
  BonusPool: {
    1: () => import('@/components/BonusPool/index.vue'),
    2: () => import('@/components/BonusPool/2.vue'),
  },
  pwa_HeaderBar: {
    1: () => import('@/pwa/HeaderBar/Default.vue'),
    2: () => import('@/pwa/HeaderBar/First.vue'),
  },
  pwa_footerModal: {
    1: () => import('@/pwa/footerModal/First.vue'),
  },

  /*    tabbar     */
  tabbar_DrawerLeft: {
    1: () => import('@/views/tabbar/components/DrawerLeft/index.vue'),
  },
  tabbar_DrawerLeft_backHeader: {
    1: () => import('@/views/tabbar/components/DrawerLeft/components/backHeader/1/index.vue'),
    close: {
      1: '/first/svg/close.svg',
    }
  },
  tabbar_DrawerLeft_gameList_categories: {
    1: () => import('@/views/tabbar/components/DrawerLeft/components/gameList/categories/1/index.vue'),
    icon: {
      1: (key: string) => `/first/svg/sort/${key}.svg`,
      2: '',
    }
  },
  tabbar_DrawerLeft_gameList_platform: {
    1: () => import('@/views/tabbar/components/DrawerLeft/components/gameList/platform/1/index.vue'),
    icon: {
      1: (key: string) => `/icons/platform/${key}.png`,
      2: '',
    }
  },
  tabbar_DrawerLeft_hotGame: {
    1: () => import('@/views/tabbar/components/DrawerLeft/components/hotGame/1/index.vue'),
  },
  tabbar_DrawerLeft_language: {
    1: () => import('@/views/tabbar/components/DrawerLeft/components/language/1/index.vue'),
  },
  tabbar_DrawerLeft_activityList: {
    1: () => import('@/views/tabbar/components/DrawerLeft/components/activityList/1/index.vue'),
  },
  tabbar_DrawerLeft_depositAndWithdrawal: {
    1: () => import('@/views/tabbar/components/DrawerLeft/components/depositAndWithdrawal/1/index.vue'),
  },
  tabbar_DrawerLeft_customerService: {
    1: () => import('@/views/tabbar/components/DrawerLeft/components/customerService/1/index.vue'),
  },
  tabbar_tabBar: {
    1: () => import('@/views/tabbar/components/tabBar/components/colorWrap/index.vue'),
    2: () => import('@/views/tabbar/components/tabBar/2/index.vue'),
    icon: {
      1: (key: string) => `/first/svg/tabbar/${key}-1.svg`,
      2: (key: string) => `/first/svg/tabbar/${key}_on.svg`,
    }
  },
  tabbar_tabBar_tabItem: {
    1: () => import('@/views/tabbar/components/tabBar/components/tabItem/1/index.vue'),
    icon: {
      1: (key: string) => `/first/svg/tabbar/${key}-1.svg`,
    }
  },
  tabbar_tabBar_flexibleTab: {
    2: () => import('@/views/tabbar/components/tabBar/components/flexibleTab/2/index.vue'),
    icon: {
      1: '/first/svg/tabbar/deposit_on.svg',
    }
  },
  tabbar_inicio_HeaderBar: {
    1: () => import('@/views/tabbar/tabs/inicio/components/HeaderBar/1/index.vue'),
    menu: {
      1: {
        open: '/first/svg/menu2.svg',
        close: '/first/svg/menu1.svg',
      },
    }
  },
  tabbar_inicio_HeaderBar_assets: {
    1: () => import('@/views/tabbar/tabs/inicio/components/HeaderBar/components/assets/1/index.vue'),
    2: () => import('@/views/tabbar/tabs/inicio/components/HeaderBar/components/assets/2/index.vue'),
    asset: {
      1: '/icons/tabbar/withdraw3.png',
    },
    deposit: {
      1: '/first/svg/tabbar/deposit_on.svg',
    },
  },
  tabbar_inicio_loginButton: {
    1: () => import('@/views/tabbar/tabs/inicio/components/button/index.vue'),
  },
  tabbar_inicio_iconButton: {
    1: () => import('@/views/tabbar/tabs/inicio/components/button/iconButton/1/index.vue'),
    icon: {
      1: '/svg/search.svg',
    }
  },
  tabbar_inicio_dropdown: {
    1: () => import('@/views/tabbar/tabs/inicio/components/dropdown/1/index.vue'),
    beforeIcon: {
      1: '/first/svg/select-icon.svg',
    },
    afterIcon: {
      1: {
        up: '/first/svg/select-icon.svg',
        down: '/first/svg/select-icon.svg',
      },
    }
  },
  tabbar_inicio_HeaderBar_registerButton: {
    1: () => import('@/views/tabbar/tabs/inicio/components/HeaderBar/components/registerButton/index.vue'),
  },
  tabbar_inicio_HeaderBar_languageButton: {
    1: () => import('@/views/tabbar/tabs/inicio/components/HeaderBar/components/languageButton/index.vue'),
    language: {
      1: '/first/svg/earth.svg',
    }
  },
  tabbar_inicio_HeaderBar_searchButton: {
    1: () => import('@/views/tabbar/tabs/inicio/components/HeaderBar/components/searchButton/index.vue'),
    search: {
      1: '/svg/search.svg',
    }
  },
  tabbar_inicio_SwiperView: {
    1: () => import('@/views/tabbar/tabs/inicio/components/SwiperView/1/index.vue'),
  },
  tabbar_inicio_ActivityBar: {
    1: () => import('@/views/tabbar/tabs/inicio/components/ActivityBar/1/index.vue'),
    5: () => import('@/views/tabbar/tabs/inicio/components/ActivityBar/5/index.vue'),
    activity: {
      1: ['/first/svg/activity1.svg', '/first/svg/activity2.svg', '/first/svg/activity3.svg'],
    }
  },
  tabbar_inicio_MarqueeView: {
    1: () => import('@/views/tabbar/tabs/inicio/components/MarqueeView/index.vue'),
  },
  tabbar_inicio_MarqueeView_marquee: {
    1: () => import('@/views/tabbar/tabs/inicio/components/MarqueeView/components/marquee/index.vue'),
    megaphone: {
      1: '/first/svg/broadcast.svg',
    }
  },
  tabbar_inicio_MarqueeView_message: {
    1: () => import('@/views/tabbar/tabs/inicio/components/MarqueeView/components/message/1/index.vue'),
    2: () => import('@/views/tabbar/tabs/inicio/components/MarqueeView/components/message/2/index.vue'),
    3: () => import('@/views/tabbar/tabs/inicio/components/MarqueeView/components/message/3/index.vue'),
    icon: {
      1: '/svg/message1.svg',
      2: '/svg/message2.svg',
    }
  },
  tabbar_inicio_Segment: {
    1: () => import('@/views/tabbar/tabs/inicio/components/Segment/index.vue'),
    5: () => import('@/views/tabbar/tabs/inicio/components/Segment/5/index.vue'),
  },
  tabbar_inicio_Segment_nav: {
    1: () => import('@/views/tabbar/tabs/inicio/components/Segment/components/nav/1/index.vue'),
    icon: {
      1: (key: string) => `/svg/sort/${key}1.svg`,
    },
    image: {
      2: (key: PlatformType) => {
        if (key === 'ONE_API_HOT') {
          return '/first/svg/sort/POPULAR.svg'
        }
        const position = platformImagePosition[key]
        if (position) {
          const imageUrl = new URL('@/assets/img/inicio/platform2.png', import.meta.url)
          return `background-image: url(${imageUrl.href});${position}`
        }
        return ''
      },
    }
  },
  tabbar_inicio_GameWrapper: {
    1: () => import('@/views/tabbar/tabs/inicio/components/GameWrapper/index.vue'),
  },
  tabbar_inicio_GameWrapper_Wrapper: {
    2: () => import('@/views/tabbar/tabs/inicio/components/GameWrapper/components/Wrapper/2/index.vue'),
  },
  tabbar_inicio_GameWrapper_WrapperHead: {
    1: () => import('@/views/tabbar/tabs/inicio/components/GameWrapper/components/WrapperHead/index.vue'),
  },
  tabbar_inicio_GameWrapper_WrapperHead_start: {
    1: () => import('@/views/tabbar/tabs/inicio/components/GameWrapper/components/WrapperHead/components/start/1/index.vue'),
    2: () => import('@/views/tabbar/tabs/inicio/components/GameWrapper/components/WrapperHead/components/start/2/index.vue'),
  },
  tabbar_inicio_GameWrapper_WrapperHead_center: {
    1: () => import('@/views/tabbar/tabs/inicio/components/GameWrapper/components/WrapperHead/components/center/1/index.vue'),
    2: () => import('@/views/tabbar/tabs/inicio/components/GameWrapper/components/WrapperHead/components/center/2/index.vue'),
    3: () => import('@/views/tabbar/tabs/inicio/components/GameWrapper/components/WrapperHead/components/center/3/index.vue'),
  },
  tabbar_inicio_GameWrapper_WrapperHead_arrow: {
    1: () => import('@/views/tabbar/tabs/inicio/components/GameWrapper/components/WrapperHead/components/arrow/1/index.vue'),
    icon: {
      left: '/svg/arrow-left2.svg',
      right: '/svg/arrow-right2.svg',
    }
  },
  tabbar_inicio_PartView: {
    1: () => import('@/views/tabbar/tabs/inicio/components/PartView/index.vue'),
  },
  tabbar_inicio_PartView_gamePlatform: {
    1: () => import('@/views/tabbar/tabs/inicio/components/PartView/components/gamePlatform/1/index.vue'),
    2: () => import('@/views/tabbar/tabs/inicio/components/PartView/components/gamePlatform/2/index.vue'),
    icon: {
      1: '/svg/game-platform-logo-1.svg',
    },
    subIcon: {
      1: '/svg/star.svg',
    }
  },
  tabbar_inicio_PartView_socialMedia: {
    1: () => import('@/views/tabbar/tabs/inicio/components/PartView/components/socialMedia/1/index.vue'),
    2: () => import('@/views/tabbar/tabs/inicio/components/PartView/components/socialMedia/2/index.vue'),
  },
  tabbar_inicio_PartView_paymentPartner: {
    1: () => import('@/views/tabbar/tabs/inicio/components/PartView/components/paymentPartner/1/index.vue'),
  },
  tabbar_inicio_AppInstall: {
    6: () => import('@/views/tabbar/tabs/inicio/components/AppInstall/6/index.vue'),
    7: () => import('@/views/tabbar/tabs/inicio/components/AppInstall/7/index.vue'),
    ios: {
      1: '/svg/apple.svg',
    },
    android: {
      1: '/svg/android.svg',
    }
  },
  tabbar_inicio_FooterContent: {
    1: () => import('@/views/tabbar/tabs/inicio/components/FooterContent/index.vue'),
  },
  tabbar_inicio_FooterContent_footerHead: {
    1: () => import('@/views/tabbar/tabs/inicio/components/FooterContent/components/footerHead/1/index.vue'),
    2: () => import('@/views/tabbar/tabs/inicio/components/FooterContent/components/footerHead/2/index.vue'),
    age18: {
      1: '/first/svg/age18.svg',
    }
  },
  tabbar_inicio_FooterContent_footerCenter: {
    1: () => import('@/views/tabbar/tabs/inicio/components/FooterContent/components/footerCenter/1/index.vue'),
    2: () => import('@/views/tabbar/tabs/inicio/components/FooterContent/components/footerCenter/2/index.vue'),
    age18: {
      1: '/first/svg/age18.svg',
    }
  },
  tabbar_inicio_CustomerServiceButton: {
    1: () => import('@/views/tabbar/tabs/inicio/components/CustomerServiceButton/index.vue'),
    image: {
      1: '/images/support.png',
    },
    icon: {
      1: '/first/svg/support.svg',
      2: '/svg/support1.svg',
    }
  },
  tabbar_inicio_ToTopButton: {
    1: () => import('@/views/tabbar/tabs/inicio/components/ToTopButton/index.vue'),
    toTop: {
      1: '/svg/to-top1.svg',
      2: '/svg/to-top2.svg',
      3: '/svg/to-top3.svg',
    }
  }
}
