<template>
  <div class="wrapper" :style="props.style">
    <component v-for="item in componentList" :key="item.componentId" :is="item.component" :sticky="sticky" v-bind="{ ...$attrs, ...item.options }" />
  </div>
</template>

<script setup lang="ts">


const props = defineProps({
  componentList: {
    type: Array,
    default: () => []
  },
  sticky: {
    type: Boolean,
    default: false
  },
  style: {
    type: Object,
    default: () => ({})
  }
})

</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>