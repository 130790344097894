import { useRouter } from 'vue-router';
import { useSegmentLoad } from '@/hooks/useLoadComponent';

import useCommonLogic from '@/views/tabbar/tabs/inicio/components/Segment/components/platformLayout/commonLogic';


const useMouseMove = () => {
  const isDown = ref(false);
  const startX = ref(0);
  const scrollLeft = ref(0);
  const disableTab = ref(false); // 定义导航标签禁用状态

  /**
   * @description 鼠标按下事件
   * @param e 事件
   */
  function handleMouseDown(e: any) {
    isDown.value = true;
    startX.value = e.pageX - e.currentTarget.offsetLeft;
    scrollLeft.value = e.currentTarget.scrollLeft;
  }
  function handleMouseUp(e: any) {
    isDown.value = false;
    disableTab.value = false;
  }
  function handleMouseLeave() {
    isDown.value = false;
    disableTab.value = false;
  }
  function handleMouseMove(e: any) {
    if (!isDown.value) return;
    e.preventDefault();
    disableTab.value = true;
    const x = e.pageX - e.currentTarget.offsetLeft;
    const walk = (x - startX.value);
    e.currentTarget.scrollLeft = scrollLeft.value - walk;
  }

  return {
    disableTab,
    handleMouseDown,
    handleMouseUp,
    handleMouseLeave,
    handleMouseMove,
  }
}


export default (props: any, emits: any) => {

  const router = useRouter();
  const segmentRef = ref(null);

  const { tabs, hotTab, tabValue, gameStore, scrollToElement } = useCommonLogic(props, emits);
  const tabsIdList = computed(() => tabs.value.map((item) => String(item.id)));

  const sortHomeGamesList = computed(() => {
    if (gameStore.homeGames.length) {
      return gameStore.homeGames.sort(<T extends { gameTypeSort: number | null }>(a: T, b: T) => {
        const gameTypeSortA = a.gameTypeSort ?? 0;
        const gameTypeSortB = b.gameTypeSort ?? 0;
        return gameTypeSortB - gameTypeSortA;
      });
    }
    return [];
  });

  const goToCategory = () => {
    if (tabValue.value == hotTab.value.id) {
      return;
    }
    const platformId = Number(tabValue.value);
    for (let i = 0; i < sortHomeGamesList.value.length; i++) {
      const { gameType, platformList } = sortHomeGamesList.value[i] as { gameType: "SPORTS" | "ELECTRONIC" | "CHESS" | "FISHING" | "VIDEO" | "LOTTERY", platformList: any[] };
      for (let j = 0; j < platformList.length; j++) {
        const { id } = platformList[j];
        if (id === platformId) {
          if (gameType === 'SPORTS') {
            router.push('/game/category/sport');
            break;
          }
          router.push(`/game/category/${gameType}/${platformId}`);
          break;
        }
      }
    }
    tabValue.value = hotTab.value.id;
    segmentRef.value?.$el.scrollTo({
      left: 0,
      behavior: 'auto'
    });
  }

  // 跳转到厂商平台
  const goToPlatform = () => {
    const platformId = tabValue.value === hotTab.value.id ? 0 : tabValue.value
    router.push(`/game/platform/${platformId}`)
    tabValue.value = hotTab.value.id;
  }



  const selectPlatformTab = () => {
    let list = [];
    if (tabValue.value === hotTab.value.id) {
      list = gameStore.allGameList.filter((item: any) => !tabsIdList.value.includes(String(item.id)));
    } else {
      list = gameStore.allGameList.filter((item: any) => String(item.id) == tabValue.value);
    }
    scrollToElement(null);
    emits('tabChange', { filterGameList: list })
  }
  const { tabExecutionEvent2 } = useSegmentLoad()

  const tabMethods = reactive<{
    goToPlatform: () => void;
    selectPlatformTab: () => void;
    goToCategory: () => void;
  }>({
    goToPlatform,
    goToCategory,
    selectPlatformTab,
  });

  const tabChange = async (event: any) => {
    const value = event.detail.value;
    if (!value) return;
    tabValue.value = value;
    tabMethods[tabExecutionEvent2.value as keyof typeof tabMethods]()
  }

  const unRowWatch = watch(() => gameStore.allGameList, (list) => {
    if (tabValue.value === hotTab.value.id) {
      const filterGameList = list.filter((item: any) => !tabsIdList.value.includes(String(item.id)));
      emits('tabChange', { filterGameList })
      setTimeout(() => {
        unRowWatch()
      }, 10000)
    }
  }, { immediate: true })


  return {
    ...useMouseMove(),
    tabs,
    tabValue,
    tabChange,
    segmentRef,
  }
}
