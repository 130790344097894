<!-- 浮动标签栏 -->
<template>
<div class="segment-container-bg">
  <div class="segment-container relative">
    <div class="w-full z-10" :class="sticky ? 'rounded-b-[7px]' : 'rounded-[7px]'">
      <ion-segment ref="segmentRef" mode="ios" :scrollable="true" v-model="tabValue" @ionChange="tabChange"
        @mousedown="handleMouseDown"
        @mouseup="handleMouseUp"
        @mouseleave="handleMouseLeave"
        @mousemove="handleMouseMove"
      >
        <div v-for="item in tabs" :key="item.code" class="btn-wall">
          <ion-segment-button :value="String(item.id)" :disabled="disableTab" class="relative" :class="tabValue == String(item.id) ? 'active' : ''">
            <div v-if="tabValue == String(item.id)" class="bug"></div>
            
            <progressiveImages class="logo" :src="loadImageSrc(item.code)"/>

            <div class="label-wall flex justify-center items-center">
              <ion-icon class="label-icon" src="/svg/gamepad.svg" />
              <span class="label-text text-[0.625rem] font-medium">{{item[labelKey]}}</span>
            </div>
          </ion-segment-button>
        </div>
      </ion-segment>
    </div>
  </div>
 </div>
</template>

<script setup lang="ts">
import { IonSegment, IonSegmentButton, IonImg, IonIcon } from '@ionic/vue';
import ProgressiveImages from '@/components/GameCard/progressiveImages.vue';
import { useSegmentLoad } from '@/hooks/useLoadComponent';
import useSegmentLogic from '@/views/tabbar/tabs/inicio/components/Segment/components/platformLayout/rowLayout/rowPlatformLogic'


const emit = defineEmits(['tabChange']); // 定义默认传递事件方法(update:modelValue为v-model传值)

interface Props {     // 定义默认绑定接参
  sticky: boolean;    // 是否固定
  height: number;     // 高度
}

const props = defineProps<Props>()// 定义默认绑定参数

const {
  tabs,
  tabValue,
  disableTab,
  tabChange,
  handleMouseDown,
  handleMouseUp,
  handleMouseLeave,
  handleMouseMove,
} = useSegmentLogic(props, emit)

const { labelKey, loadImageSrc } = useSegmentLoad()
</script>

<style lang="less" scoped>
@import './index.less';
</style>
