import { mainMediaListApi } from '@/api/normal';
import { redirectUrl } from '@/utils/app';
import { urlCheck } from '@/hooks/UrlCheck';
import { capitalize } from 'lodash-es';

import { MainMediaModel } from "@/api/normal/model";


export default () => {
  const mediaList = ref<MainMediaModel[]>([]); // 主媒体列表

  const getMainMedia = async () => {
    try {
      const res = await mainMediaListApi();
      mediaList.value = res || [];
    } catch (error) {
      console.error('failed to get main media', error);
    }
  }

    /**
   * @description 点击链接
   */
  const linkHandle = (url: string, social?: string) => {
    redirectUrl(urlCheck(url), capitalize(social))
  }

  getMainMedia();

  return {
    mediaList,
    linkHandle
  }
}