
import { useTenantStore } from "@/store//tenant";
import { copy } from '@/hooks/Copy';


export default (emits: any) => {
  const tenantStore = useTenantStore();	// 租户信息
  const merchantName = computed(() => tenantStore.tenantInfo?.name); // 商户名
  const appLogo = computed(() => tenantStore.tenantInfo?.logo);

  return {
    appLogo,
    merchantName,
  }
}
