import { bannerListApi } from '@/api/normal/index'
import { SuggestionActivityListModel } from "@/api/activity/model";
import { handleSidebarJumpType, handleSideValueType, handleInlineNavigation } from '@/utils/inlineNavigation'


export default () => {
  const activityList = ref<SuggestionActivityListModel>([]) // 活动列表

  const handleActivityList = async () => {
    try {
      const activityData: any = await bannerListApi({ bannerType: 'lobby_sidebar_banner' });
      if (activityData?.length) {
        activityList.value = activityData.map((item: any) => {
          let value: number | string | undefined = '';
          let valueType: string = '';
          if (item.targetType == 'internal') {
            const targetValue = JSON.parse(item.targetValue);
            value = handleInlineNavigation(targetValue)
            valueType = handleSideValueType(targetValue)
          } else {
            value = item.targetValue
          }
          return {
            id: item.id,
            image: item.imageUrl,
            name: item.name,
            type: handleSidebarJumpType(item.targetType),
            value,
            showName: item.showName,
            valueType
          }
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  handleActivityList()

  /**
 * @description 活动导航标签点击事件
 */
  const menuActivityHandle = (item: SuggestionActivityListModel[0]) => {
    useLinkHandle(item.type, item.value, item.valueType)
  }

  return {
    activityList,
    menuActivityHandle
  }
}