export default {
  main: {
    children: [
      {
        position: 'tabs',
        children: [
          {
            componentName: 'tabbar_tabBar',
            componentId: 1,
            tabTextMaps: ['main.inicio', 'main.promo', 'main.invite', 'main.withdraw',  'main.perfil'],
            wrapStyle: {
              borderRadius: '0.875rem 0.875rem 0 0',
              boxShadow: '0 0.0625rem 0 0 var(--ep-color-background-fill-glow-primary-opacity-40, rgba(58, 140, 227, 0.40)) inset',
            },
            children: [
              {
                componentName: 'tabbar_tabBar_tabItem',
                componentId: 1,
                imageList: [
                  {
                    name: 'icon',
                    id: 1,
                  }
                ],
              }
            ]
          }
        ]
      },
      {
        position: 'other',
        children: [
          {
            componentName: 'tabbar_DrawerLeft',
            componentId: 1,
            style: {
              '--width': '80.5%',
            },
            children: [
              {
                position: 'header',
                children: [
                  {
                    componentName: 'tabbar_DrawerLeft_backHeader',
                    componentId: 1,
                    closeProps: {
                      slot: 'start',
                    },
                    logoProps: {
                      slot: 'end',
                    },
                    imageList: [
                      {
                        name: 'close',
                        id: 1,
                      }
                    ],
                  },
                  {
                    componentName: 'tabbar_inicio_HeaderBar_assets',
                    componentId: 2,
                    clickType: 'apply',
                    needMenuToggle: true,
                    style: {
                      padding: '1.0625rem 0.75rem',
                    },
                    currencyStyle: {
                      fontSize: '0.75rem',
                      fontWeight: '500',
                    },
                    numberStyle: {
                      fontSize: '0.75rem',
                      fontWeight: '500',
                    },
                    imageList: [
                      {
                        name: 'asset',
                        id: 1,
                      },
                    ],
                    children: [
                      {
                        componentName: 'tabbar_inicio_loginButton',
                        componentId: 1,
                        class: 'login',
                        style: {
                          flex: 1,
                          '--padding-top': '0.9375rem',
                          '--padding-bottom': '0.9375rem',
                          '--border-radius': '0.875rem',
                        }
                      },
                      {
                        componentName: 'tabbar_inicio_HeaderBar_registerButton',
                        componentId: 1,
                        class: 'register',
                        style: {
                          flex: 1,
                          '--padding-top': '0.9375rem',
                          '--padding-bottom': '0.9375rem',
                          '--border-radius': '0.875rem',
                        }
                      }
                    ]
                  },
                ]
              },
              {
                position: 'content',
                style: {
                  '--padding-start': '0.75rem',
                  '--padding-end': '0.75rem',
                  '--padding-top': '0.625rem',
                  '--padding-bottom': '1rem',
                },
                children: [
                  {
                    componentName: 'tabbar_DrawerLeft_hotGame',
                    componentId: 1,
                  },
                  {
                    componentName: 'tabbar_DrawerLeft_language',
                    componentId: 1,
                    style: {
                      marginTop: '1.375rem',
                      border: '0.0625rem solid var(--ep-color-border-default)',
                    }
                  },
                  {
                    componentName: 'tabbar_inicio_dropdown',
                    componentId: 1,
                    labelKey: 'main.entrar',
                    style: {
                      marginTop: '0.9375rem',
                    },
                    dropdownStyle: {
                      border: '0.0625rem solid var(--ep-color-border-default)',
                    },
                    imageList: [
                      {
                        name: 'beforeIcon',
                        id: 1,
                      },
                      {
                        name: 'afterIcon',
                        id: 1,
                      }
                    ],
                    children: [
                      {
                        componentName: 'tabbar_DrawerLeft_gameList_platform',
                        componentId: 1,
                        imageList: [
                          {
                            name: 'icon',
                            id: 1,
                          }
                        ],
                      },
                    ]
                  },
                  {
                    componentName: 'tabbar_DrawerLeft_activityList',
                    componentId: 1,
                  },
                  {
                    componentName: 'tabbar_inicio_AppInstall',
                    componentId: 7,
                    class: 'line-before',
                    style: {
                      flexDirection: 'row',
                      '--line-margin-top': '1.4375rem',
                      '--line-margin-bottom': '0.75rem',
                      '--line-color': 'var(--ep-color-border-default)',
                    },
                    imageList: [
                      {
                        name: 'ios',
                        id: 1,
                      },
                      {
                        name: 'android',
                        id: 1,
                      }
                    ]
                  },
                  {
                    componentName: 'tabbar_inicio_PartView_socialMedia',
                    componentId: 1,
                    class: 'line-before',
                    style: {
                      '--line-margin-top': '1.4375rem',
                      '--line-margin-bottom': '0.5rem',
                      '--line-color': 'var(--ep-color-border-default)',
                    },
                  },
                ]
              },
            ]
          },
          {
            componentName: 'pwa_footerModal',
            componentId: 1,
          }
        ]
      },
    ],
  },
  inicio: {
    children: [
      {
        position: 'header',
        children: [
          {
            componentName: 'tabbar_inicio_HeaderBar',
            componentId: 1,
            imageList: [
              {
                name: 'menu',
                id: 1,
              }
            ],
            children: [
              {
                componentName: 'tabbar_inicio_HeaderBar_assets',
                componentId: 1,
                clickType: 'apply',
                currencyStyle: {
                  fontSize: '0.75rem',
                  fontWeight: '500',
                },
                numberStyle: {
                  fontSize: '0.75rem',
                  fontWeight: '500',
                },
                imageList: [
                  {
                    name: 'deposit',
                    id: 1,
                  },
                ],
                children: [
                  {
                    componentName: 'tabbar_inicio_loginButton',
                    componentId: 1,
                    class: 'login',
                    type: 'text',
                  },
                  {
                    componentName: 'tabbar_inicio_HeaderBar_registerButton',
                    componentId: 1,
                    class: 'register'
                  }
                ]
              },
              {
                componentName: 'tabbar_inicio_HeaderBar_languageButton',
                componentId: 1,
                imageList: [
                  {
                    name: 'language',
                    id: 1,
                  }
                ],
              }
            ]
          }
        ],
      },
      {
        position: 'content',
        children: [
          {
            componentName: 'tabbar_inicio_SwiperView',
            componentId: 1,
            style: {
              margin: '0.875rem 0.75rem 0',
              height: '10rem',
              borderRadius: '0.875rem',
            },
          },
          {
            componentName: 'tabbar_inicio_ActivityBar',
            componentId: 1,
            style: {
              margin: '0.75rem 0.75rem 0',
            },
            imageList: [
              {
                name: 'activity',
                id: 1,
              }
            ]
          },
          {
            componentName: 'tabbar_inicio_MarqueeView',
            componentId: 1,
            style: {
              padding: '0 0.75rem',
              marginTop: '1.5625rem',
              height: '2.5rem',
            },
            children: [
              {
                componentName: 'tabbar_inicio_MarqueeView_marquee',
                componentId: 1,
                style: {
                  paddingRight: '0.4375rem',
                  borderRadius: '0.875rem',
                },
                imageList: [
                  {
                    name: 'megaphone',
                    id: 1,
                  },
                ],
                children: [
                  {
                    componentName: 'tabbar_inicio_MarqueeView_message',
                    componentId: 1,
                  }
                ]
              },
              {
                componentName: 'tabbar_inicio_iconButton',
                componentId: 1,
                btnStyle: {
                  marginLeft: '0.625rem',
                  width: '2.5rem',
                  height: '2.5rem',
                  '--border-radius': '0.875rem',
                  '--border-width': '0.0625rem',
                  '--border-style': 'solid',
                },
                iconStyle: {
                  fontSize: '1.5rem',
                },
                imageList: [
                  {
                    name: 'icon',
                    id: 1,
                  }
                ]
              }
            ],
          },
          {
            componentName: 'tabbar_inicio_Segment',
            componentId: 1,
            style: {
              padding: '1rem 0.75rem 0.5rem',
            },
            children: [
              {
                componentName: 'tabbar_inicio_Segment_nav',
                componentId: 1,
                type: 1,
                imageList: [
                  {
                    name: 'image',
                    id: 2,
                  }
                ]
              },
            ]
          },
          {
            componentName: 'tabbar_inicio_GameWrapper',
            componentId: 1,
            gameWrapperOptions: {
              titleType: '2',
              headType: '1',
              size: 6,
              row: 2,
              hotSize: 9,
              hotRow: 3,
            },
            children: [
              {
                componentName: 'BonusPool',
                componentId: 1,
                style: {
                  order: 2,
                }
              },
              {
                componentName: 'tabbar_inicio_GameWrapper_Wrapper',
                componentId: 2,
                children: [
                  {
                    componentName: 'tabbar_inicio_GameWrapper_WrapperHead',
                    componentId: 1,
                    style: {
                      marginTop: '1.75rem',
                      marginBottom: '0.9375rem'
                    },
                    children: [
                      {
                        componentName: 'tabbar_inicio_GameWrapper_WrapperHead_start',
                        componentId: 1,
                        styleId: 'svg-theme-color2',
                      },
                      {
                        componentName: 'tabbar_inicio_GameWrapper_WrapperHead_center',
                        componentId: 3,
                      }
                    ]
                  }
                ]
              },
            ]
          },
          {
            componentName: 'tabbar_inicio_PartView_gamePlatform',
            componentId: 2,
            imageList: [
              {
                name: 'icon',
                id: 1,
              },
              {
                name: 'subIcon',
                id: 1,
              },
            ]
          },
          {
            componentName: 'tabbar_inicio_FooterContent',
            componentId: 1,
            style: {
              marginTop: '1rem',
              padding: '1.375rem 0 2.0625rem',
            },
            children: [
              {
                componentName: 'tabbar_inicio_FooterContent_footerHead',
                componentId: 2,
                style: {
                  margin: '0 0.75rem',
                },
              },
              {
                componentName: 'tabbar_inicio_PartView_socialMedia',
                componentId: 1,
                style: {
                  padding: '1.75rem 0.75rem 0',
                  justifyContent: 'flex-start',
                },
              },
              {
                componentName: 'tabbar_inicio_FooterContent_footerCenter',
                componentId: 2,
                style: {
                  margin: '2.25rem 0.75rem 0',
                },
                imageList: [
                  {
                    name: 'age18',
                    id: 1,
                  }
                ]
              },
              {
                componentName: 'tabbar_inicio_AppInstall',
                componentId: 7,
                style: {
                  margin: '3.0625rem 0.75rem 0',
                },
                imageList: [
                  {
                    name: 'ios',
                    id: 1,
                  },
                  {
                    name: 'android',
                    id: 1,
                  }
                ]
              }
            ]
          },
          {
            componentName: 'tabbar_inicio_CustomerServiceButton',
            componentId: 1,
            type: 3,
            imageList: [
              {
                name: 'icon',
                id: 1,
              }
            ],
          },
          {
            componentName: 'tabbar_inicio_ToTopButton',
            componentId: 1,
            type: 4,
            style: {
              border: 'none',
              borderRadius: '0.875rem',
            },
            imageList: [
              {
                name: 'toTop',
                id: 2,
              }
            ],
          }
        ],
      }
    ],
  }
}