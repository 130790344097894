<template>
  <ion-badge class="total">{{ props.gameListLength }}</ion-badge>
</template>

<script setup lang="ts">
  import { IonBadge } from '@ionic/vue';

  
  const props = defineProps({
    gameListLength: { type: Number, required: true },
  })
</script>