<template>
  <ion-button class="unset-btn" mode="md" :class="type === 'text' ? 'text-btn' : ''" :fill="buttonType">
    <slot />
  </ion-button>
</template>

<script setup lang="ts">
import { IonButton } from '@ionic/vue';

const props = defineProps({
  type: {
    type: String,
    default: 'default',
  }
})
const fillType = {
  text: 'clear',
  line: 'outline',
  default: 'solid',
}

const buttonType = computed(() => {
  return fillType[props.type];
})

</script>

<style lang="less" scoped>
ion-button {
  &.unset-btn {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    margin: 0;
    min-height: min-content;
    min-width: min-content;
  }

  &.text-btn {
    --background: transparent;

    &::part(native) {
      background: transparent !important;
    }
  }
}

</style>
