<template>
  <section class="activity-list">
    <slot :list="activityList" :handleClick="menuActivityHandle"/>
  </section>
</template>

<script setup lang="ts">
import useLogic from './logic';

const {
  activityList,
  menuActivityHandle
} = useLogic()

</script>

<style scoped lang="less">
@import './layout.less';
</style>
