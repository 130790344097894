<script setup lang="ts">
import { formatMoneyToShow } from '@/utils/custom'
const emit = defineEmits<{
  (e: 'clickHandle'): void

}>()
defineProps<{
  bannerUrl: string
  rateOfGift: number
  amountOfGift: number
  merchantCy: string
  isDefaultBanner: boolean
}
>()

const bannerHandle = () => {
  emit(`clickHandle`)
} 
</script>
<template>

  <div class="banner" :style='{ backgroundImage: `url("${bannerUrl}")` }' @click="bannerHandle">
    <p class="text">
      <span>{{ $t('viewsTabbar.depositLeft') }}</span>
      <span class="rate">{{ formatMoneyToShow(rateOfGift) }}%</span>
      <span>{{ $t('viewsTabbar.depositRight') }}</span>
    </p>
    <p class="bottom flex-between">
      <span>{{ $t('viewsTabbar.discountReward') }}</span>
      <span>
        <span class="rate">{{ formatMoneyToShow(rateOfGift) }}%</span>
        <span>{{ `${merchantCy} +${formatMoneyToShow(amountOfGift)}`
          }}</span>
      </span>
    </p>
  </div>

</template>

<style scoped lang="less">
#recharge-main-bannerArea-index {
  .style(@rateColor: --color-primary-800, @color: --color-text-100, @bottomBg: rgba(0, 0, 0, .5)) {
    .rate-style {
      color: var(@rateColor);
      font-size: var(--font-size-18);
      font-weight: var(--font-weight-bold);

    }

    .banner {
      width: 22.5rem;
      height: 7.5rem;
      margin: .75rem auto;
      background: no-repeat center center;
      background-size: cover;
      position: relative;
      box-sizing: border-box;
      padding: 15px 20px;
      border-radius: var(--rounded-middle);

      .text {
        width: 210px;
        color: var(@color);
        font-size: var(--font-size-14);
        line-height: 22px;

        .rate {
          .rate-style()
        }
      }

      .bottom {
        width: 100%;
        height: 1.5625rem;
        box-sizing: border-box;
        padding: 0 20px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: @bottomBg;
        border-radius: 0 0 var(--rounded-middle) var(--rounded-middle);
        font-size: .75rem;
        color: var(@color);


        .rate {

          margin-right: 10px;
          .rate-style()
        }
      }




    }

  }
}

#recharge-main-bannerArea-index.style();


.banner.purple-light {
  #recharge-main-bannerArea-index.style(@color: --text-color-white-100, @rateColor: --accent-color-orange, @bottomBg: rgba(84, 30, 184, .5));

}

.amber-purple {
  #recharge-main-bannerArea-index.style(@color: --text-color-light-purple-1-100, @rateColor: --accent-color-orange, @bottomBg: rgba(59, 52, 102, .5));
}
.auroral-yellow {
  #recharge-main-bannerArea-index.style( @rateColor: --accent-color-yellow-1);
}

#recharge-main-bannerArea-new-index {
  .style() {
    .rate-style {
      color: var(--ep-color-text-warning);
      font-size: 1.125rem;
      font-weight: 700;

    }

    .banner {
      width: 22.5rem;
      height: 7.5rem;
      margin: .75rem auto;
      background: no-repeat center center;
      background-size: cover;
      position: relative;
      box-sizing: border-box;
      padding: 15px 20px;
      border-radius: .375rem;

      .text {
        width: 13.125rem;
        color: var(--ep-color-text-default);
        font-size: .875rem;
        line-height: 1.375rem;

        .rate {
          .rate-style()
        }
      }

      .bottom {
        width: 100%;
        height: 1.5625rem;
        box-sizing: border-box;
        padding: 0 1.25rem;
        position: absolute;
        left: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .5);
        border-radius: 0 0 .375rem .375rem;
        font-size: .75rem;
        color: var(--ep-color-text-default);


        .rate {

          margin-right: .625rem;
          .rate-style()
        }
      }




    }

  }
}

.new-skin-symbol {
  #recharge-main-bannerArea-new-index.style();
}


</style>
