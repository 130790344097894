<script setup lang="ts">
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonModal,
} from '@ionic/vue';
import type { CheckboxCustomEvent, SearchbarCustomEvent } from '@ionic/vue';
import { SelectInfo } from '../type'
import { getTheme } from '@/theme/hooks'
const { theme } = getTheme()
const modal = ref();
const emit = defineEmits<{
  (e: 'update:modelValue', code: string): void
}>()
const activeCode = defineModel<string>({ required: true })
let props = withDefaults(defineProps<{
  selectList: SelectInfo[]
  placeholder: string

}
>(), {

});
const filteredItems = ref([...props.selectList]);
const handleClick = (it: SelectInfo) => {
  modal.value?.$el.dismiss()
  if (it.code === activeCode.value) return
  emit('update:modelValue', it.code)
}
const activeSelect = computed(() => {
  return props.selectList.find(it => it.code === activeCode.value)
})
const onClick = () => {
  filteredItems.value = [...props.selectList];
}


const searchbarInput = (ev: SearchbarCustomEvent) => {
  filterList(ev.target.value);
};
const filterList = (searchQuery: string | undefined | null) => {

  if (!searchQuery) {
    filteredItems.value = [...props.selectList];
    return
  }

  const normalizedQuery = searchQuery.toLowerCase();
  filteredItems.value = props.selectList.filter((item) => {
    return item.name.toLowerCase().includes(normalizedQuery);
  });

};
</script>
<template>
  <div class="select-border" id="select-blank-card" @click="onClick">
    <div class="placeholder" v-if="!activeCode">{{ placeholder }}</div>
    <div v-else class="flex-start">
      <!-- <div class="img-wrap">
        <img alt="" :src="activeSelect?.icon" />
      </div> -->
      <div class="name-wrap">
        <div class="name-inner">{{ activeSelect?.name }}</div>
      </div>


    </div>
  </div>

  <ion-modal trigger="select-blank-card" ref="modal" :initial-breakpoint="0.5" :breakpoints="[0, 0.25, 0.5, 0.75]">
    <ion-content class="select-content">
      <ion-list lines="full" mode="md" class='select-list'>
        <ion-searchbar placeholder="Search" @ionInput="searchbarInput($event)"></ion-searchbar>
        <ion-item mode="md" lines="full" :button="true" v-for="it in filteredItems"
          :class="['select-item', { active: it.code === activeCode }, theme]" :key="it.code" @click="handleClick(it)">
          <!-- <div class="img-wrap">
            <img alt="" :src="it.icon" />
          </div> -->
          <div class="name-wrap">
            <div class="name-inner">{{ it.name }}</div>
          </div>


        </ion-item>



      </ion-list>
    </ion-content>

  </ion-modal>
</template>

<style scoped lang="less">
.select-content {
  --padding-bottom: 1.625rem
}

.select-border {
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-line);
  box-sizing: border-box;
  padding: 0 0.75rem;
  color: var(--color-text-100);
  border-radius: var(--rounded-small);

  .placeholder {
    color: var(--color-text-40);
    font-weight: var(--font-weight-regular);
  }



}


.img-wrap {
  width: 1.625rem;
  height: 1.625rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: .3125rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.name-wrap {
  line-height: inherit;

  .name-inner {
    display: inline-block;
    line-height: 1;
    vertical-align: middle;
  }


}


.select-list.list-md {
  background: var(--color-bg-400);
  padding: 0;
}

ion-item.select-item {
  --background: var(--color-bg-400);

  --background-hover: var(--color-bg-200);
  --background-hover-opacity: 1;
  color: var(--color-text-40);
}

ion-item.active {
  --background: var(--theme-color-800);
  color: var(--color-text-100);
}

ion-item.active.purple-light {
  --background: var(--theme-color-800);
  color: var(--color-text-secondary-1);
}

ion-item.select-item.blue-default {
  --background: #070B18;


}

ion-item.active.auroral-yellow {
  color: var(--color-text-black-100);
}

#withdraw-main-bindBankTab-new-index {
  .style() {
    .select-content {
      --padding-bottom: 1.625rem;
    }

    .select-border {
      width: 100%;
      height: 100%;
      border: 1px solid var(--ep-color-border-selected);
      box-sizing: border-box;
      padding: 0 0.75rem;
      color: var(--ep-color-text-default);
      border-radius: 4px;

      .placeholder {
        color: var(--ep-color-text-weaker);
        font-weight: 400;
      }



    }


    .img-wrap {
      width: 1.625rem;
      height: 1.625rem;
      border-radius: 50%;
      overflow: hidden;
      margin-right: .3125rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .name-wrap {
      line-height: inherit;

      .name-inner {
        display: inline-block;
        line-height: 1;
        vertical-align: middle;
      }


    }


    .select-list.list-md {
      background: var(--ep-color-background-fill-surface-raised-L1);
      padding: 0;
    }

    ion-item.select-item {
      --background: var(--ep-color-background-fill-surface-raised-L1);

      --background-hover: var(--ep-color-background-fill-active-primary);
      --background-hover-opacity: 1;
      color: var(--ep-color-text-weaker);
    }

    ion-item.active {
      --background: var(--ep-color-background-fill-active-primary);
      color: var(--color-text-100);
    }
  }
}

.new-skin-symbol {
  #withdraw-main-bindBankTab-new-index.style();
}
</style>
