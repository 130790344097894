<template>
  <div class="badge" @click="navigateToMessage">
    <ion-icon class="message" :src="loadImage.icon" />
    <Dot />
  </div>
</template>

<script setup lang="ts">
import Dot from '@/views/tabbar/tabs/inicio/components/MarqueeView/components/message/1/index.vue';
import useMessageLogic from '@/views/tabbar/tabs/inicio/components/MarqueeView/components/message/logic';


const props = defineProps({
  loadImage: {
    type: Object,
    default: () => ({})
  }
})

const { navigateToMessage } = useMessageLogic();
</script>

<style lang="less" scoped>
@import './index.less';
</style>
