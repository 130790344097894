<template>
  <div v-if="hotGameList.length" class="hot-game-box">
    <div class="hot-game-title">
      <div class="hot-game-icon-box">
        <ion-img class="hot-game-icon" src="/icons/platform/ONE_API_HOT-2.png" />
        <ion-label class="label">{{ $t("sort.POPULAR") }}</ion-label>
      </div>
      <ion-label class="hot-game-intro">{{ $t("main.gameSelection") }}</ion-label>
    </div>
    <div class="hot-game-list-box">
      <div class="hot-game-list" @mousedown="handleMouseDown" @mouseup="handleMouseUp" @mouseleave="handleMouseLeave" @mousemove="handleMouseMove">
        <ion-menu-toggle 
          v-for="item in hotGameList" 
          :key="item.id"
          class="card" 
          @click="goToGame(item)"
        >
          <ion-img class="img" :src="item.logo" />
        </ion-menu-toggle>
      </div>
    </div>
  </div>
</template>


<script setup lang="ts">
import { IonMenuToggle, IonImg, IonLabel } from '@ionic/vue';
import useHotGame from '../logic';
import useCustomDraggable from '@/hooks/useCustomDraggable';


const { goToGame, hotGameList } = useHotGame();

const {
  handleMouseDown,
  handleMouseUp,
  handleMouseLeave,
  handleMouseMove
} = useCustomDraggable();
</script>

<style scoped lang="less">
@import './index.less';
</style>
